import { NotificationStatus, WorkType } from '../../generated/schemas';
import { NotificationStatusFilter } from '../../common/enums/NotificationStatusFilterEnum';

const notificationStatuses = {
  [NotificationStatus.Confirmed]: 'Confirmed',
  [NotificationStatus.Read]: 'Read not confirmed',
  [NotificationStatus.Received]: 'Received',
  [NotificationStatus.Sent]: 'Sent',
  [NotificationStatusFilter.Confirmed]: 'Confirmed',
  [NotificationStatusFilter.Read]: 'Read not confirmed',
  [NotificationStatusFilter.Received]: 'Received',
  [NotificationStatusFilter.Sent]: 'Sent',
};

const workTypes = {
  [WorkType.Industrial]: 'Industrial (Isolated James Bay)',
  [WorkType.RegularLabor]: 'Regular Work',
  [WorkType.IndustrialSite]: 'Industrial Site',
  [WorkType.HeavyIndustries]: 'Heavy Industries',
  [WorkType.Pipeline]: 'Pipeline',
  [WorkType.PowerLineErection]: 'Electrical line - Erection',
  [WorkType.GeneralElectricPostLine]: 'Substation Electrical - General Line',
  [WorkType.CivilEngineeringAndRoads]: 'Civil Engineering And Roads',
  [WorkType.WindTurbine]: 'Wind Turbines',
  [WorkType.Barracks]: 'Barracks',
  [WorkType.JamesBayAndIsolatedWorksite]: 'James Bay And Isolated Worksite',
  [WorkType.OutsideProvinceQuebec]: 'Outside Province Quebec',
};

const notificationType = {
  newOrdreDeTravailNotification: 'Assignation',
  ordreDeTravailContinuityNotification: 'Continuity',
  ordreDeTravailUpdatedNotification: 'Modification',
  ordreDeTravailCancelledNotification: 'Cancellation',
  unassignedOrdreDeTravailNotification: 'Removal',
};

const headers = {
  ordreDeTravailNumber: 'OT Number',
  lastNotificationType: 'Notification',
  operatorBranchNumber: 'Operator branch',
  operator: 'Crane operator',
  unitNo: 'Unit No',
  clientName: 'Client',
  ordreDeTravailStatus: 'OT Status',
  notificationStatus: 'Reading Status',
  date: 'Date',
  workType: 'Nature of the work',
  workDescription: 'Work description',
  representativeName: 'Project manager',
  site: 'Worksite',
  dispatchBranchNumber: 'Dispatch branch',
  salesBranchNumber: 'Sales branch',
};

const list = {
  title: 'Acknowledgements',
  notificationStatuses,
  headers,
  workTypes,
  notificationType,
};

export const acknowledgement = {
  list,
};
