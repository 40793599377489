import { orderBy } from 'lodash';
import { TimeRange } from '../../types/TimeRange';
import { formatTime, newDateFromTimeString, newDateFromTimeStringAndDuration, splitTime } from './DateTimeHelper';
import { addDays, differenceInMinutes } from 'date-fns';

export const midnight = '00:00';

export const getTimeRangeToTime = (timeRange: TimeRange, date?: Date | string | null): string => {
  const dateTo = getTimeRangeToDate(timeRange, date);
  if (!dateTo) return '';
  return formatTime(dateTo);
};

export const getTimeRangeToDate = (timeRange: TimeRange, date?: Date | string | null): Date | undefined => {
  if (!timeRange || !timeRange.from) return undefined;
  const rawDate = newDateFromTimeStringAndDuration(timeRange.from, timeRange.durationInMinutes || 0, date);
  if (!rawDate) return undefined;
  return addDays(rawDate, timeRange.startsOnNextDay ? 1 : 0);
};

const minuteAtMidnight = 24 * 60;
export const isTimeRangeEndingAtMidnight = (timeRange: TimeRange): boolean => {
  const [hours, minutes] = splitTime(timeRange.from || ':');
  if (hours < 0 || minutes < 0) return false;
  const startingMinute = hours * 60 + minutes;
  const endingMinute = startingMinute + (timeRange.durationInMinutes || 0);
  return endingMinute === minuteAtMidnight;
};

export const timeRangeDurationFromNewFromTime = (timeRange: TimeRange, fromTime: string, date?: Date | string | null): number => {
  if (!timeRange) return 0;
  const initialDurationInMinutes = timeRange.durationInMinutes || 0;
  const fromTimeDate = newDateFromTimeString(timeRange.from, date);
  const newFromTimeDate = newDateFromTimeString(fromTime, date);
  const offset = fromTimeDate && newFromTimeDate ? differenceInMinutes(fromTimeDate, newFromTimeDate) : 0;
  return initialDurationInMinutes + offset;
};

export const timeRangeDurationFromNewToTime = (timeRange: TimeRange, toTime: string, date?: Date | string | null): number => {
  if (!timeRange) return 0;
  const fromTimeDate = newDateFromTimeString(timeRange.from, date);
  const toTimeDate = newDateFromTimeString(toTime === midnight ? '24:00' : toTime, date);
  return fromTimeDate && toTimeDate ? differenceInMinutes(toTimeDate, fromTimeDate) : 0;
};

export const isLastEntryEndingAtMidnight = (timeRanges: TimeRange[]): boolean => {
  if (timeRanges.length) {
    const sortedTimeRanges = orderBy(timeRanges, ['from']);
    return isTimeRangeEndingAtMidnight(sortedTimeRanges[sortedTimeRanges.length - 1]);
  }
  return false;
};

export const sortTimeRanges = (a: TimeRange, b: TimeRange): number => {
  const aFromDate = addDays(newDateFromTimeString(a.from), a.startsOnNextDay ? 1 : 0);
  const bFromDate = addDays(newDateFromTimeString(b.from), b.startsOnNextDay ? 1 : 0);
  if (!aFromDate || !bFromDate) {
    const aToDate = getTimeRangeToDate(a);
    const bToDate = getTimeRangeToDate(b);
    if (!aToDate || !bToDate) {
      return 0;
    }
    if (aToDate < bToDate) {
      return -1;
    }
    if (aToDate > bToDate) {
      return 1;
    }
    return 0;
  }
  if (aFromDate < bFromDate) {
    return -1;
  }
  if (aFromDate > bFromDate) {
    return 1;
  }
  return 0;
};
