const languages = {
  en: 'English',
  fr: 'French',
};

const access = {
  title: 'Access',
  rolesLabel: 'Roles',
  accessLevels: 'Access Levels',
  error: 'An error occured. Could not load access section.',
  roles: {
    admin: 'Admin',
    billingClerk: 'Billing Clerk',
    dispatcher: 'Dispatcher',
  },
};

export const userSettings = {
  about: 'About',
  language: 'Language',
  languages,
  settings: 'Settings',
  access,
};
