import { config } from './config';

export const msalConfig = {
  auth: {
    authority: config.AUTH_AUTHORITY || '',
    clientId: config.AUTH_CLIENT_ID || '',
    redirectUri: config.AUTH_REDIRECT_URI || '',
    postLogoutRedirectUri: config.AUTH_LOGIN_REDIRECT || '',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
  state: config.AUTH_LOGIN_REDIRECT,
};

export const apiRequest = {
  redirectUri: config.AUTH_REDIRECT_URI || '',
  scopes: ['openid', 'profile', 'email', 'offline_access', config.AUTH_API_SCOPE || ''],
};
