import { LocaleHelper } from '@bryntum/schedulerpro';
import EnLocale from '@bryntum/schedulerpro/locales/schedulerpro.locale.En.js';

const craneOperatorDispatch = {
  craneOperator: 'Crane Operator',
  branch: 'Branch',
  branchNameField: 'shortNameEn',
  groupSortField: 'nameEn',
};

const craneDispatch = {
  crane: 'Crane',
  branch: 'Branch',
  branchNameField: 'shortNameEn',
  editCrane: 'Edit crane',
  editRollingStock: 'Edit rolling stock',
  unavailabilities: 'Unavailabilities',
  preferredOperators: 'Preferred operators',
};

const locale = LocaleHelper.mergeLocales(EnLocale, {
  localeName: 'en',
  localeDesc: 'English (Canada)',
  localeCode: 'en-CA',
  craneOperatorDispatch,
  craneDispatch,
});

export default locale;
