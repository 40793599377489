import { IncompatibilityTypeEnum } from '../components/Incompatibility/models/incompatibilityModels';

const headers = {
  incompatibilityType: 'Incompatibility type',
  employee: 'Employee',
  incompatibleWith: 'Incompatible with',
  description: 'Description',
  updatedAt: 'Updated at',
  createdBy: 'Created by',
};

const incompatibilityType = {
  [IncompatibilityTypeEnum.Client]: 'Client',
  [IncompatibilityTypeEnum.WorkSite]: 'WorkSite',
  [IncompatibilityTypeEnum.Employee]: 'Employee',
  [IncompatibilityTypeEnum.Equipment]: 'Equipment',
};

const incompatibility = {
  incompatibility: 'Incompatibility',
  newIncompatibility: 'New Incompatibility',
  list: {
    headers,
  },
  incompatibilityType,
  deleteConfirmationModal: {
    title: 'Delete',
    text: 'Do you really want to delete this incompatibility?',
  },
  validation: {
    sameEmployee: 'Use other employee',
  },
};

const tabs = {
  incompatibility: 'Incompatibilities',
};

export const configuration = {
  title: 'Configuration',
  incompatibility,
  tabs,
  validation: {
    required: '*Required',
    saveSuccessMessage: 'Modifications saved successfully.',
  },
};
