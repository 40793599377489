export enum Path {
  ValidationsPage = 'validations',
  NextPrefacturationSuffix = 'prefacturation',
  CodesDeFacturation = 'codes-de-facturation',
  CodesDeFacturationPage = 'validations-codes-de-facturation',
  AcknowledgementsPage = 'acknowledgements',
  OrdresDeTravailPage = 'ordres-de-travail',
  ContractAgreementsPage = 'contract-agreements',
  TimesheetsPage = 'timesheets',
  UserSettingsPage = 'user-settings',
  PrivacyPolicyPage = 'privacy-policy',
  LogoutPage = 'logout',
  DispatchPage = 'dispatch',
  MockDataGeneratorPage = 'generate-ots',
  ConfigurationPage = 'configuration',
  EmployeeClaims = 'employee-claims',
}

export enum DispatchPath {
  CraneOperatorView = 'crane-operator',
  CraneView = 'crane',
  Overview = 'overview',
}

export enum ConfigurationPath {
  Incompatibilities = 'incompatibilities',
}
