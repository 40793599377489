const languages = {
  en: 'English',
  fr: 'Français',
};

const app = {
  title: 'GUAY',
  version: 'Version',
};
const sideBar = {
  actions: 'Actions',
  consultation: 'Consultation',
  settings: 'Settings',
};
const auth = {
  logout: 'Logout',
  redirectToConnectionPage: 'You will be redirected to the connection page.',
  unauthorizedLogin: 'Unauthorized Login',
  unauthorizedLoginMessage: 'You cannot access this application with this email address',
};

const navBar = {
  userSettings: 'User settings',
  privacyPolicy: 'Privacy policy',
};

const logoutModal = {
  title: 'Confirmation',
  confirmationText: 'Do you really want to log off ?',
  confirmButton: 'Disconnect',
};

const insufficientPermissions = {
  title: 'Insufficient permissions',
};

const notifications = {
  departureDate: 'Departure date',
  arrivalDate: 'Arrival date',
  readyDate: 'Ready date',
  isSecondServing: 'Seconds serving',
  capacity: 'Capacity',
  equipmentType: 'Equipment type',
  preventSubcontracting: 'Prevent subcontractor',
  title: 'Notifications',
  newValue: 'New value',
  oldValue: 'Old value',
  client: 'Client',
  accessories: 'Accessories',
  status: 'Status',
  ot: 'The OT',
  continuity: 'The continuity',
  modified: 'has been modified.',
  continuityModified: 'has been modified.',
  durationInDays: 'Duration in days',
  empty: 'No notification.',
  hoursPerDay: 'Hours per day',
  address: 'Address',
  workType: 'Work type',
  boomLength: 'Boom length',
  jibLength: 'Jib length',
  offsetAngle: 'Offset',
  counterweight: 'Counterweight',
  makeAndModels: 'Make and model',
  radius: 'Radius',
  maxWeight: 'Maximum lift weight',
  preventReplacement: 'Prevent replacement',
  serviceCallStatus: {
    NONE: 'None',
    DEFAULT: 'Default',
    InWriting: 'In writing',
    Reserved: 'Reserved',
    Transferred: 'Transferred',
    Delayed: 'Delayed',
    Dispatched: 'Dispatched',
    Canceled: 'Canceled',
  },
  days: 'days',
  hours: 'hours',
  feet: 'ft',
  pounds: 'lb',
  of: 'of',
  from: 'from',
  to: 'to',
  true: 'True',
  false: 'False',
  transportsLastDayContinuityChanged: {
    title_last: 'The last day of continuity {{clientName}} has changed from {{oldDate}} to {{newDate}}.',
    title_first: 'The first day of continuity {{clientName}} has changed from {{oldDate}} to {{newDate}}.',
    content_last: 'Transports have been moved to new continuity end date ({{newDate}}).',
    content_first: 'Transports have been moved to new continuity start date ({{newDate}}).',
  },
  transportRemoved: {
    title: 'Continuity service call {{clientName}} has been modified.',
    content_single: 'One planned transport of type {{codeSubcode}} - {{codeDescription}} from {{date}} has been removed.',
    content_multiple:
      '{{quantityDeleted}} planned transports of type {{codeSubcode}} - {{codeDescription}} from {{date}} have been removed.',
  },
  impossibleOptimization: {
    title: 'Automated dispatch failed for {{clientName}} on {{date}}.',
  },
  craneConfigurationUpdated: {
    title: 'The crane configuration has been modified in the service call. The associated ordres de travail need to be rescheduled.',
  },
};

export const layout = {
  app,
  auth,
  insufficientPermissions,
  languages,
  navBar,
  sideBar,
  logoutModal,
  notifications,
};
