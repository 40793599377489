import { Button, ButtonTypeMap, Typography } from '@mui/material';
import { ButtonHTMLAttributes, forwardRef } from 'react';

type Props = ButtonTypeMap['props'] &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
  };

export const ActionButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { sx = props.color ? {} : { color: 'rgba(0,0,0,0.87)' }, type, label, ...rest } = props;
  return (
    <Button {...rest} sx={sx} type={type} ref={ref}>
      <Typography textTransform='uppercase' sx={{ fontFamily: 'Inter', fontSize: 13, fontWeight: 500 }}>
        {label}
      </Typography>
    </Button>
  );
});
