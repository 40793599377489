import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { EmployeeAccessRecord } from '../models/models';
import { Path } from '../../paths';
import axios, { AxiosError } from 'axios';

export const useGetEmployeeClaimsAndRoles = (): UseQueryResult<EmployeeAccessRecord, AxiosError> => {
  return useQuery({
    queryKey: [Path.EmployeeClaims],
    queryFn: () => axios.get<EmployeeAccessRecord>('/employee/access').then((response) => response.data),
    gcTime: 1000 * 60 * 10,
  });
};
