import { WorkKind } from '../../generated/schemas';
import { CompilablePremiumsEnum, NonCompilablePremiumsEnum } from '../enums/PremiumsEnum';

const timesheetType = {
  linked: 'Linked',
  mixed: 'Mixed',
  nonLinked: 'Non Linked',
};

const billableTimeSheetEntry = {
  client: 'Client name',
  title: 'OT:',
  contractAgreement: 'Contract Agreement',
  crane: 'Crane',
  comments: 'Comments',
  workType: 'Work Type',
};

const nonBillableTimeSheetEntry = {
  comments: 'Comments',
  title: 'Non-linked',
  workType: 'Work Type',
};

const timeSheetEntryStatus = {
  validated: 'Validated',
  submitted: 'Submitted',
  completed: 'Finished',
  ongoing: 'Ongoing',
  submissionError: 'Error',
};

const timeSheetEntryValidationStatus = {
  documentsReceived: 'Documents received',
  inProgress: 'In progress',
  open: 'Open',
  submitted: 'Submitted',
};

const headers = {
  craneOperator: 'Crane operator',
  date: 'Date',
  type: 'Type',
  status: 'TS Status',
  branchNumber: 'Operator Branch',
  dispatchBranch: 'Dispatch Branch',
  jobCode: 'Job Code',
  validationStatus: 'Validation Status',
};

const list = {
  title: 'Timesheets',
  headers,
};

const submitDailyTimeSheetModal = {
  title: 'Submit time sheet?',
  content: 'Every time sheet entry will be submitted.',
  cancel: 'Cancel',
  submit: 'Submit',
};

const saveDailyTimeSheetBlockModal = {
  title: 'Warning',
  content: `Once the timesheet completed, the entry won't be editable on the mobile application.`,
  cancel: 'Cancel',
  save: 'Save',
};

const manualTimeSheetNumberModal = {
  title: 'Link Timesheet',
  content: `Enter Timesheet number to link to this WeLift timesheet entry. This new number will replace the existing one.`,
  label: 'Number',
  cancel: 'Cancel',
  link: 'Link',
};

const detail = {
  title: 'TimeSheet',
  export: 'Export',
  saveSuccessMessage: 'Modifications to daily timesheet saved successfully',
  addTimeRange: 'add',
  submit: 'Submit',
  reviewedBy: 'Reviewed by',
  submitDailyTimeSheetModal,
  saveDailyTimeSheetBlockModal,
  manualTimeSheetNumberModal,
};

const distanceRange = {
  [WorkKind.TravelExpenses49To72Km]: '49 to 72 km',
  [WorkKind.TravelExpenses73To88Km]: '73 to 88 km',
  [WorkKind.TravelExpenses89To119Km]: '89 to 119 km',
};

const timesheetTimes = {
  title: 'Times',
  edit: 'Edit',
  from: 'From',
  to: 'To',
  poste: 'Poste',
  type: 'Type',
  quantity: 'Qty',
  workOrderNumber: 'Equi./WO',
  gtNumber: '#GT',
  simple: 'Simple',
  singleAndAHalf: 'Half',
  double: 'Double',
  automaticPrime: 'Aut P.',
  variablePrime: 'Var P.',
  automaticPrimeLong: 'Auto Prime',
  variablePrimeLong: 'Vari Prime',
  rate: 'Rate',
  total: 'Total',
};

const premium = {
  title: 'Premiums',
  manTime: 'Man time',
  noBreak: 'No Break',
  noLunch: 'No Lunch',
  manTransport: 'Man transport',
  meal: 'Meal',
  preparation: 'Preparation',
  taxis: 'Taxis',
  parking: 'Parking',
  travellingExpenses: 'Traveling expenses',
  pension: 'Pension',
  workKind: 'Work Kind',
  branch: 'Branch',
  contractAgreement: 'CA Number',
  poste: 'Poste',
  roomAndBoardDay: 'Day',
  roomAndBoardTwoPlusWeeks: '2+ weeks',
};

const premiums = {
  [CompilablePremiumsEnum.ManTimePremium]: 'Man time',
  [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
  [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
  [CompilablePremiumsEnum.ManTransportPremium]: 'Man transport',
  [CompilablePremiumsEnum.PreparationPremium]: 'Preparation',
  [NonCompilablePremiumsEnum.MealPremium]: 'Meal',
  [NonCompilablePremiumsEnum.TaxisPremium]: 'Taxis',
  [NonCompilablePremiumsEnum.ParkingPremium]: 'Parking',
  [NonCompilablePremiumsEnum.TravellingExpensesPremium]: 'Traveling expenses',
  [NonCompilablePremiumsEnum.PensionPremium]: 'Pension',
  [NonCompilablePremiumsEnum.CarAllowancePremium]: 'Car Allowance',
};

const inlineTimesheet = {
  title: 'Timesheet',
  header: {
    numOT: 'OT Number',
    from: 'From',
    to: 'To',
    branch: 'Branch',
    qty: 'Qty',
    poste: 'Poste',
    unitNo: 'Unit No.',
    numCA: 'CA Number',
    single: 'Single',
    singleAndHalf: 'Half',
    double: 'Dbl.',
    variablePrime: 'Auto Prime',
    automaticPrime: 'Vari Prime',
    rate: 'Rate',
  },
  footer: {
    total: 'Total',
  },
};

const summary = {
  time: 'Time',
  comments: 'Comments',
  premiums: 'Premiums',
  premiumValue: 'Quantity | Hours',
  contractAgreement: 'Contract Agreement',
};

const timeRangeSection = {
  onError: `Please correct errors in the timesheet before saving.`,
  cantEdit: 'A linked/unlinked block is already being edited.',
  manuallyLinked: 'Manual number',
  linkPaperTimeSheet: 'Link timesheet',
};

export const timesheet = {
  summary,
  days_one: '{{count}} day',
  days_other: '{{count}} days',
  timesheets: 'Timesheets',
  submitErrorMsg: 'A technical error occurred while submitting to the AS400',
  submitSuccessMsg: 'The daily timesheet was successfully submitted to the AS400',
  manuallyCreated: 'Manually created',
  timeSheetEntryStatus,
  timesheetType,
  list,
  billableTimeSheetEntry,
  nonBillableTimeSheetEntry,
  detail,
  premium,
  timesheetTimes,
  distanceRange,
  premiums,
  inlineTimesheet,
  timeRangeSection,
  timeSheetEntryValidationStatus,
};
