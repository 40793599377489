import { LocaleHelper, LocaleManager } from '@bryntum/schedulerpro';
import EnLocale from './locales/en-CA.js';
import FrLocale from './locales/fr-CA.js';

export const loadSchedulerLocales = () => {
  LocaleHelper.publishLocale(FrLocale);
  LocaleHelper.publishLocale(EnLocale);
};

export const setSchedulerLocale = (resolvedLanguage) => {
  LocaleManager.applyLocale(resolvedLanguage);
};
