import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const GroupsSplitIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid='Group-Split-Icon'>
    <svg viewBox='0 0 24 24' fill='currentColor'>
      <path d='M16.24 13.65C15.07 13.14 13.63 12.75 12 12.75C10.37 12.75 8.93 13.14 7.76 13.66C6.68 14.13 6 15.21 6 16.39V18H18V16.38C18 15.21 17.32 14.13 16.24 13.65Z' />
      <path d='M6 11C6 12.1 5.1 13 4 13C2.9 13 2 12.1 2 11C2 9.9 2.9 9 4 9C5.1 9 6 9.9 6 11Z' />
      <path d='M4 14C4.39 14 4.76 14.04 5.13 14.1C4.73 14.78 4.5 15.56 4.5 16.39V18H0V16.43C0 15.62 0.48 14.9 1.22 14.58C2.07 14.21 3.01 14 4 14Z' />
      <path d='M15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12C13.66 12 15 10.66 15 9Z' />
      <path
        fill='#DA1B31'
        d='M23.8572 13L22.7122 14.145L21.2722 12.705L20.5622 13.415L22.0022 14.855L20.8572 16L23.8572 16L23.8572 13ZM23.8572 11L23.8572 8L20.8572 8L22.0022 9.145L19.6522 11.5L15.8572 11.5L15.8572 12.5L20.0622 12.5L22.7122 9.855L23.8572 11Z '
      />
    </svg>
  </SvgIcon>
);

export const GroupsClosedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid='Group-Closed-Icon'>
    <svg viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_5930_45720)'>
        <path
          d='M16.3828 13.65C15.2128 13.14 13.7728 12.75 12.1428 12.75C10.5128 12.75 9.07282 13.14 7.90282 13.66C6.82282 14.13 6.14282 15.21 6.14282 16.39V18H18.1428V16.38C18.1428 15.21 17.4628 14.13 16.3828 13.65Z'
          fill='black'
          fillOpacity='0.54'
        />
        <path
          d='M6.14282 11C6.14282 12.1 5.24282 13 4.14282 13C3.04282 13 2.14282 12.1 2.14282 11C2.14282 9.9 3.04282 9 4.14282 9C5.24282 9 6.14282 9.9 6.14282 11Z'
          fill='black'
          fillOpacity='0.54'
        />
        <path
          d='M4.14282 14C4.53282 14 4.90282 14.04 5.27282 14.1C4.87282 14.78 4.64282 15.56 4.64282 16.39V18H0.142822V16.43C0.142822 15.62 0.622822 14.9 1.36282 14.58C2.21282 14.21 3.15282 14 4.14282 14Z'
          fill='black'
          fillOpacity='0.54'
        />
        <path
          d='M15.1428 9C15.1428 7.34 13.8028 6 12.1428 6C10.4828 6 9.14282 7.34 9.14282 9C9.14282 10.66 10.4828 12 12.1428 12C13.8028 12 15.1428 10.66 15.1428 9Z'
          fill='black'
          fillOpacity='0.54'
        />
      </g>
    </svg>
    ;
  </SvgIcon>
);
