import { Box, Tooltip } from '@mui/material';
import Cleave from 'cleave.js/react';
import { CSSProperties, forwardRef } from 'react';
import { backgroundErrorColor, black } from '../../constants/colors';
import { midnight } from '../../helpers/TimeRangeWithoutToHelpers';
import { wrapReactRef } from './WrapReactRef';

type Props = {
  disabled?: boolean;
  value: string;
  onChange?: (newValue: string) => void;
  onBlur?: (newValue: string) => void;
  onFocus?: () => void;
  error?: boolean;
  errorMessage?: string;
  style?: CSSProperties;
  name?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InlineTimeInput = forwardRef<HTMLInputElement, Props>(({ style = {}, ...props }: Props, ref): JSX.Element => {
  const onTimeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const time = event.target.value ?? '';
    if (time.length === 5) {
      props.onChange?.(event.target.value);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    props.onChange?.(event.target.value + midnight.substring(event.target.value.length));
    props.onBlur?.(event.target.value + midnight.substring(event.target.value.length));
  };

  const time = ['0', '1', '2'].some((digit) => props.value === digit) ? `0${props.value}` : props.value;

  return (
    <Tooltip title={props.errorMessage} arrow placement='top' enterDelay={700} enterNextDelay={700}>
      <Box
        sx={{
          '&>input': {
            paddingLeft: '.5rem',
            border: '1px solid transparent',
            outline: 'none',
            width: '100%',
            backgroundColor: 'inherit',
            fontFamily: 'Inter',
            fontSize: '0.875rem',
            lineHeight: '31px',
            color: 'black',
            ...(props.error &&
              !props.disabled && {
                color: (theme) => theme.palette.error.main,
                borderBottom: (theme) => `1px solid ${theme.palette.error.main}`,
                backgroundColor: backgroundErrorColor,
              }),
            ...(style || {}),
          },
          '&>input:focus': {
            border: `1px solid ${black}`,
          },
        }}>
        <Cleave
          htmlRef={wrapReactRef(ref)}
          data-testid='inline-time-input'
          options={{ time: true, timeFormat: '24', timePattern: ['h', 'm'] }}
          placeholder='--:--'
          onChange={onTimeChange}
          onFocus={props.onFocus}
          value={time}
          disabled={props.disabled}
          onBlur={onBlur}
          aria-disabled={props.disabled}
          aria-invalid={props.error}
          name={props.name ?? undefined}
        />
      </Box>
    </Tooltip>
  );
});
