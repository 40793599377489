import { Params, defer } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { GetCATimesheetRecord } from '../models/models';
import { ValidationParams } from '../params';
import { caDailyTimeSheetQueryOptions } from '../hooks/useGetCADailyTimesheet';
import { OrdreDeTravailInfoRecord } from '../../common/models/models';
import { ordreDeTravailInfoQueryOptions } from '../../common/hooks/useGetOrdreDeTravailInfo';

export type DailyTimesheetLoaderData = {
  otInfo: { data?: OrdreDeTravailInfoRecord };
  csDailyTimeSheet: { data?: GetCATimesheetRecord };
};

export const validationDailyTimesheetLoader =
  (queryClient: QueryClient) =>
  ({ params }: { params: Params<ValidationParams> }): ReturnType<typeof defer> => {
    const fetchCsDailyTimesheet = queryClient.fetchQuery({
      ...caDailyTimeSheetQueryOptions({ dailyTimeSheetId: params.timesheetId!, contractAgreementId: params.contractAgreementId! }),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });

    const fetchOTInfo = queryClient.fetchQuery({
      ...ordreDeTravailInfoQueryOptions(params.ordreDeTravailId!),
      staleTime: 1000 * 60 * 2,
    });

    return defer({ csDailyTimeSheet: fetchCsDailyTimesheet, otInfo: fetchOTInfo });
  };
