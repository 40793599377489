import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const accessConfigurationRoles: Role[] = ['billingClerk', 'admin'];

export const returnForCorrectionAbilities = 'returnForCorrection';

export const definedReturnForCorrectionAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (!!roles?.some((role) => accessConfigurationRoles.includes(role))) {
    can('access', returnForCorrectionAbilities);
  }
};
