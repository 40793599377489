import { Add, Delete, Group, Groups } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { GroupsSplitIcon as Split } from './GroupsSplitIcon';

interface IconPropsWithReadonly extends SvgIconProps {
  readonly?: boolean;
}

interface ReadonlyIconProps extends IconPropsWithReadonly {
  icon: React.FC<SvgIconProps>;
}

export const ReadonlyIcon = ({ readonly, icon, ...iconProps }: ReadonlyIconProps): JSX.Element => {
  const IconComponent = icon;
  return readonly ? (
    <IconComponent
      {...iconProps}
      sx={{
        ...iconProps.sx,
        filter: 'opacity(0.4) saturate(0)',
      }}
      aria-disabled={true}
    />
  ) : (
    <IconComponent {...iconProps} aria-disabled={false} />
  );
};

export const RemoveIcon = ({ readonly, sx, ...iconProps }: IconPropsWithReadonly): JSX.Element => {
  return (
    <ReadonlyIcon
      icon={Delete}
      readonly={readonly}
      sx={{ ...(sx || {}), opacity: readonly ? 0 : 1, transition: 'ease 0.3s opacity' }}
      {...iconProps}
    />
  );
};

export const AddIcon = ({ readonly, ...iconProps }: IconPropsWithReadonly): JSX.Element => {
  return <ReadonlyIcon icon={Add} readonly={readonly} {...iconProps} />;
};

export const GroupIcon = ({ readonly, ...iconProps }: IconPropsWithReadonly): JSX.Element => {
  return <ReadonlyIcon icon={Group} readonly={readonly} {...iconProps} />;
};

export const GroupsIcon = ({ readonly, ...iconProps }: IconPropsWithReadonly): JSX.Element => {
  return <ReadonlyIcon icon={Groups} readonly={readonly} {...iconProps} />;
};

export const GroupSplitIconReadonly = ({ readonly, ...iconProps }: IconPropsWithReadonly): JSX.Element => {
  return <ReadonlyIcon icon={Split} readonly={readonly} {...iconProps} />;
};
