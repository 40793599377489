const timeRange = {
  fromBiggerThanTo: 'The end time cannot be before the start time',
  isOverlapping: 'This time range is overlapping',
  isOverlapping_time: 'This time range is overlapping: {{ type }} at {{ time }}',
  mustEndInFifteenMinutesIncrement: 'Time must end in 15 minutes increment',
  mustMatchDuration: 'The sum of all hours must match the duration',
  noTimeRangeOverlap: 'Cannot overlap two time ranges',
};

const timePeriodType = {
  serviceHour: 'Crane Service',
  lunchBreak: 'Lunch Break',
  mechanicalFailure: ' Mechanical Failure',
  manTime: 'Man Time',
  manTransport: 'Man Transport',
  noBreakTime: 'No Break',
  noLunchTime: 'No Lunch',
};
export const schemas = {
  timeRange,
  invalidBranch: 'The branch number is invalid',
  invalidWorkType: 'The work type is invalid',
  invalidWorkKind: 'The work kind is invalid',
  invalidGtNumber: 'The GT Number is invalid',
  invalidNoLunchTimeRange: 'A time range of type "no lunch break" must have a duration of 30 minutes',
  invalidNoBreakTimeRange: 'A time range of type "no break time" must have a duration of 15 minutes',
  invalidPreparationTimeRange: 'A time range of type "preparation" must have a duration of 1 or 1.25 hours',
  invalidCodeOrSubcode: 'The code and subcode combination is invalid',
  invalidLabourCode: 'The labour code is invalid',
  noCodeDeFacturationInDouble: 'This code de facturation already exists.',
  totalCraneTimeError: 'Time sheet hours must be less than or equal to the crane service hours in the contract agreement',
  required: 'Required',
  indemnitySplitRequiredOneEmployee: 'At least one employee must be selected',
  singleFeeIndemnityRequiredAtLeastCountOfOne: 'At least one fee must be applied to an employee',
  justificationRequired: 'A justification must be provided when the rate is modified',
  justificationPrevented: 'A justification cannot be entered if the rate stayed the same',
  requireSelection: 'At least one employee must be selected',
  requireCount: 'At least one employee must have a value different than 0',
  requirePensionType: `The pension's type is required`,
  timePeriodType,
  at: 'at',
};
