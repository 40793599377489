import { HttpStatusCode } from 'axios';
import { TFunction } from 'i18next';

export enum EntityTypeErrorEnum {
  Unknown = 'unknown',
  TimeSheet = 'timeSheet',
  OrdreDeTravail = 'ordreDeTravail',
  Incompatibility = 'incompatibility',
  ContractAgreement = 'contractAgreement',
}

export const getErrorMessage = (
  t: TFunction,
  entityType?: EntityTypeErrorEnum,
  status?: HttpStatusCode | undefined,
): { title: string; message: string; contactAdmin: boolean } => {
  switch (status) {
    case HttpStatusCode.NotFound: {
      const entityName = t(`entityType.${entityType ?? EntityTypeErrorEnum.Unknown}`);
      return { title: t('notFoundErrorTitle', { entityName }), message: t('notFoundErrorMessage'), contactAdmin: false };
    }
    default:
      return { title: t('defaultErrorTitle'), message: t('defaultErrorMessage'), contactAdmin: true };
  }
};

export const getHttpStatusCodeFromGraphQLErrorCode = (code: string | undefined): HttpStatusCode | undefined => {
  switch (code) {
    case 'NOT_FOUND':
      return HttpStatusCode.NotFound;
    default:
      return undefined;
  }
};
