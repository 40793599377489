import { components } from '../../../../generated/swagger-type';

export type CreateIncompatibilityData = components['schemas']['CreateIncompatibilityRequestBody'];
export type ClientRecord = components['schemas']['BasicClientRecord'];
export type WorksiteRecord = components['schemas']['BasicWorksiteRecord'];
export type EmployeeRecord = components['schemas']['BasicEmployeeRecord'];
export type EquipmentRecord = components['schemas']['EquipmentRecord'];
export type IncompatibilityRecord = components['schemas']['IncompatibilityRecord'];
export type IncompatibilityType = components['schemas']['IncompatibilityType'];
export const IncompatibilityTypeEnum: Record<Capitalize<IncompatibilityType>, IncompatibilityType> = {
  Equipment: 'equipment',
  Employee: 'employee',
  WorkSite: 'workSite',
  Client: 'client',
};
