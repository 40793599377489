import { InlineTimeInput } from '../../../common/components/inputs/InlineTimeInput';
import { getTimeRangeDurationInMinutes, rangeFinishesTomorrow } from '../../../common/helpers/TimeRangeHelpers';
import { computedTo } from '../../../common/helpers/DateTimeHelper';
import { NextDayIcon } from '../../../common/components/svg/NextDayIcon';
import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import { Ref, useCallback, useMemo, useRef } from 'react';

export interface TimeRange {
  from: string;
  durationInMinutes: number;
  startsOnNextDay?: boolean | null | undefined;
}
interface TimeRangeControlProps<T extends TimeRange> {
  name: string;
  timeRange: T;
  onBlur: () => void;
  onChange: (durationInMinutes: number) => void;
  dateFn: (startsOnNextDay: boolean) => Date;
  errorMessage: string | undefined;
  readonly: boolean;
  myRef?: Ref<HTMLInputElement>;
}

/**
 * @deprecated stop using this reference, use the one in common.
 * @note move me to common folder and remove the deprecated tag
 */
export const DurationInMinutes = <T extends TimeRange>({
  name,
  timeRange,
  onChange,
  onBlur,
  errorMessage,
  readonly,
  dateFn,
  myRef,
}: TimeRangeControlProps<T>) => {
  const theme = useTheme();
  const isPrevStartsOnNextDayRef = useRef<boolean>(false);
  const nextDay = rangeFinishesTomorrow(timeRange);

  const value = useMemo(
    () => computedTo(timeRange.from, timeRange.durationInMinutes, dateFn(timeRange.startsOnNextDay ?? false)) ?? '',
    [dateFn, timeRange.durationInMinutes, timeRange.from, timeRange.startsOnNextDay],
  );

  const handleChange = useCallback(
    (formattedTime: string) => {
      const durationInMinutes = getTimeRangeDurationInMinutes(timeRange.from, formattedTime, dateFn(timeRange.startsOnNextDay ?? false));
      if (durationInMinutes === timeRange.durationInMinutes) return;
      const applyNextDay = isPrevStartsOnNextDayRef.current && !timeRange.startsOnNextDay;
      onChange(applyNextDay ? durationInMinutes + 24 * 60 : durationInMinutes);
    },
    [dateFn, onChange, timeRange],
  );

  const handleToggleNextDay = useCallback(() => {
    const durationInMinutes = timeRange.durationInMinutes + (rangeFinishesTomorrow(timeRange) ? -24 * 60 : 24 * 60);
    isPrevStartsOnNextDayRef.current = rangeFinishesTomorrow({ ...timeRange, durationInMinutes });
    onChange(durationInMinutes);
  }, [onChange, timeRange]);

  const handleFocus = useCallback(() => {
    isPrevStartsOnNextDayRef.current = nextDay;
  }, [nextDay]);

  return (
    <Tooltip disableInteractive title={errorMessage ?? ''} placement='top' arrow>
      <Stack
        direction='row'
        sx={{
          ...(errorMessage && {
            color: theme.palette.error.main,
            borderBottom: `1px solid ${theme.palette.error.main}`,
          }),
        }}>
        <InlineTimeInput
          ref={myRef}
          name={name}
          disabled={readonly}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          error={!!errorMessage}
          value={value}
          style={{
            ...(errorMessage && {
              color: theme.palette.error.main,
            }),
          }}
        />
        <Box sx={{ paddingTop: '5px' }}>
          <NextDayIcon
            aria-label={name + '.tomorrow'}
            disabled={readonly || !!timeRange.startsOnNextDay}
            onClick={handleToggleNextDay}
            active={nextDay}
            dateToIncrement={dateFn(nextDay)}
          />
        </Box>
      </Stack>
    </Tooltip>
  );
};
