import { QueryClient } from '@tanstack/react-query';
import { Params, defer } from 'react-router-dom';
import { contractAgreementQueryOptions } from '../../hooks/shared/useGetContractAgreement';
import { ContractAgreementParams } from './params';

export const contractAgreementLoader =
  (queryClient: QueryClient) =>
  ({ params }: { params: Params<ContractAgreementParams> }): ReturnType<typeof defer> => {
    const fetchOperatorContractAgreement = queryClient.fetchQuery({
      ...contractAgreementQueryOptions(params.id!),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });
    return defer({
      contractAgreement: fetchOperatorContractAgreement,
    });
  };
