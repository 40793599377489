import { IncompatibilityTypeEnum } from '../components/Incompatibility/models/incompatibilityModels';

const headers = {
  incompatibilityType: "Type d'incompatibilité",
  employee: 'Employé',
  incompatibleWith: 'Incompatible avec',
  description: 'Description',
  updatedAt: 'Rafraîchie le',
  createdBy: 'Créé par',
};

const incompatibilityType = {
  [IncompatibilityTypeEnum.Client]: 'Client',
  [IncompatibilityTypeEnum.WorkSite]: 'Chantier',
  [IncompatibilityTypeEnum.Employee]: 'Employé',
  [IncompatibilityTypeEnum.Equipment]: 'Équipement',
};

const incompatibility = {
  incompatibility: 'Incompatibilité',
  newIncompatibility: 'Nouvelle incompatibilité',
  list: {
    headers,
  },
  incompatibilityType,
  deleteConfirmationModal: {
    title: 'Supprimer',
    text: "Voulez-vous vraiment supprimer l'incompatibilité?",
  },
  validation: {
    sameEmployee: 'Choisissez un autre employé',
  },
};

const tabs = {
  incompatibility: 'Incompatibilités',
};

export const configuration = {
  title: 'Configuration',
  incompatibility,
  tabs,
  validation: {
    required: '*Requis',
    saveSuccessMessage: 'Les modifications ont été enregistrées avec succès',
  },
};
