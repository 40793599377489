import { useTranslation } from 'react-i18next';
import { isoDateOnlyToDateUtc } from '../../../common/helpers/DateTimeHelper';
import { useFormContext, useWatch } from 'react-hook-form';
import { addDays } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { AdditionalHourLine } from './AdditionalHourLine';
import { getNewAvailableTimeRange } from '../../../common/helpers/TimeRangeHelpers';
import { TableCell, TableRow, Typography } from '@mui/material';
import { getFakeId } from '../../helpers/formHelpers';
import { ContractAgreementForm } from './mapper';
import { SectionHeader } from '../common/SectionHeader';

interface Props {
  date: string;
  readonly: boolean;
  isLoading: boolean;
  operators: { id: string; employeeId: string; name: string }[] | undefined;
}

const formKey = 'additionalHours';

/**
 * @deprecated stop using this reference, use the one in contractAgreement/components/shared.
 * @note Move me to shared folder and remove the deprecated tag
 **/
export const AdditionalHours = ({ readonly, date, isLoading, operators }: Props): JSX.Element => {
  const { t } = useTranslation('validations');
  const { control, setValue, trigger } = useFormContext<ContractAgreementForm>();
  const parentDate = useMemo(() => isoDateOnlyToDateUtc(date), [date]);

  const processDate = useCallback(
    (startsOnNextDay: boolean | null | undefined) => (startsOnNextDay ? addDays(parentDate, 1) : parentDate),
    [parentDate],
  );

  const fields = useWatch({ control, name: formKey });

  const handleAdd = useCallback(() => {
    const timeRange = getNewAvailableTimeRange(fields || []);
    const row = {
      fakeId: getFakeId(),
      from: timeRange.from,
      durationInMinutes: timeRange.durationInMinutes,
      timeEntryInformation: {
        single: 1,
        singleAndAHalf: 0,
        double: 0,
      },
      type: null,
      startsOnNextDay: timeRange.startsOnNextDay ?? false,
      employeeIds: operators?.map((operator) => operator.employeeId) || [],
    };
    setValue(formKey, !!fields?.length ? [...fields, row] : [row], { shouldValidate: true, shouldDirty: true });
  }, [fields, operators, setValue]);

  const handleRemove = useCallback(
    (_index: number) => {
      setValue(
        formKey,
        fields?.filter((_, i) => i !== _index),
        { shouldValidate: false, shouldDirty: true },
      );
      trigger(formKey);
    },
    [fields, setValue, trigger],
  );

  return (
    <SectionHeader sectionKey='additionalHours' label={t(`${formKey}.title`)} onAdd={() => handleAdd()} readonly={readonly ?? false}>
      {fields?.length ? (
        fields.map((field, index) => (
          <AdditionalHourLine
            key={field.fakeId}
            control={control}
            setValue={setValue}
            index={index}
            readonly={readonly}
            operators={operators}
            formPath={`${formKey}.${index}`}
            lineData={field}
            dateFn={processDate}
            remove={handleRemove}
          />
        ))
      ) : (
        <TableRow data-testid='additional-hours-no-data'>
          {isLoading ? null : (
            <TableCell colSpan={12}>
              <Typography variant='body2' align='center'>
                {t(`additionalHours.noData`)}
              </Typography>
            </TableCell>
          )}
        </TableRow>
      )}
    </SectionHeader>
  );
};

/**
 * @deprecated stop using this reference, use the one in contractAgreement/components/shared.
 * @note Move me to shared folder and remove the deprecated tag
 **/
export enum AdditionalHourType {
  NoBreakTime = 'noBreakTime',
  NoLunchTime = 'noLunchTime',
  ManTransport = 'manTransport',
  ManTime = 'manTime',
}
