import axios from 'axios';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../paths';

import { useLoaderData } from 'react-router-dom';
import { GetCsWithEntriesSummariesRecord } from '../models/models';
import { OrdreDeTravailScheduleLoaderData } from '../loaders/ordreDeTravailScheduleLoader';

export const getContractAgreementWithTimesheetEntriesSummariesQueryKey = (id: string | undefined): QueryKey => [
  Path.ValidationsPage,
  Path.OrdresDeTravailPage,
  id,
  'schedule',
  Path.ContractAgreementsPage,
];

export const contractAgreementWithTimesheetEntriesSummariesQueryOptions = (
  id: string | undefined,
): Pick<DefinedInitialDataOptions<GetCsWithEntriesSummariesRecord[]>, 'queryKey' | 'queryFn' | 'staleTime'> => ({
  queryKey: getContractAgreementWithTimesheetEntriesSummariesQueryKey(id),
  queryFn: async () =>
    axios.get<GetCsWithEntriesSummariesRecord[]>(`/ordre-de-travail/${id}/contract-agreement-summaries`).then((response) => response.data),
  staleTime: 2 * 60 * 1000,
});

export const useGetContractAgreementWithTimesheetEntriesSummaries = (
  ordreDeTravailId: string | undefined,
): UseQueryResult<GetCsWithEntriesSummariesRecord[], unknown> => {
  const { operatorContractAgreements } = useLoaderData() as OrdreDeTravailScheduleLoaderData;
  return useQuery({
    ...contractAgreementWithTimesheetEntriesSummariesQueryOptions(ordreDeTravailId),
    initialData: operatorContractAgreements?.data,
    enabled: !!ordreDeTravailId,
  });
};
