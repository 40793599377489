import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { inlineInputStyle } from './InlineMuiInputStyle';

type Props = Omit<TextFieldProps, 'select'>;

export const InlineSelectInput = forwardRef<HTMLDivElement, Props>(({ value, InputProps, sx, ...props }: Props, ref) => {
  const subProps = useMemo(
    () => ({
      ...InputProps,
      style: { cursor: 'pointer', ...(InputProps?.style || {}) },
      sx: {
        fontSize: '0.875rem',
        ...inlineInputStyle,
        ...(InputProps?.sx || {}),
      },
    }),
    [InputProps],
  );

  return <TextField ref={ref} {...props} select value={value || ''} sx={{ fontSize: '0.875rem', ...sx }} InputProps={subProps} />;
});
