import { ForwardedRef } from 'react';

export const wrapReactRef = (ref: ForwardedRef<HTMLInputElement>) => (inputNode: HTMLInputElement) => {
  if (!ref) {
    return;
  }
  if (typeof ref === 'function') {
    ref(inputNode);
  } else {
    ref.current = inputNode;
  }
};
