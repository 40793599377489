import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { apiRequest } from '../../authConfig';
import { config } from '../../config';
import i18n from '../../i18n';
import useSignalr from './useSignalr';
import { dispatchHubName } from '../../dispatch/constants/constants';

export const useAxios = (account: AccountInfo | null, instance: IPublicClientApplication): boolean => {
  const [isReady, setIsReady] = useState(false);
  const { connectionId } = useSignalr(`${config.HUB_BASE_URL}/${dispatchHubName}`);

  useEffect(() => {
    if (isReady && connectionId) {
      axios.defaults.headers.SignalRConnectionId = connectionId;
    }
  }, [isReady, connectionId]);

  const requestInterceptorOnFulfilled = useCallback(
    async (request: InternalAxiosRequestConfig, activeAccount: AccountInfo) => {
      const tokenRequest = { ...apiRequest, account: activeAccount };
      const controller = new AbortController();
      request.headers['Accept-Language'] = i18n.languages[0];

      try {
        const { accessToken, expiresOn } = (await instance.acquireTokenSilent(tokenRequest)) || {};
        if (expiresOn && expiresOn < new Date()) {
          controller.abort();
        } else {
          request.headers.Authorization = `Bearer ${accessToken}`;
        }
      } catch (e) {
        await instance.acquireTokenRedirect(tokenRequest);
      }

      return { ...request, signal: controller.signal };
    },
    [instance],
  );

  useEffect(() => {
    if (account && !isReady) {
      axios.defaults.baseURL = config.API_URL;
      axios.interceptors.request.use((request) => requestInterceptorOnFulfilled(request, account));
      setIsReady(true);
    }
  }, [account, isReady, requestInterceptorOnFulfilled]);

  return isReady;
};
