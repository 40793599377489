import { Backdrop, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('layout');
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <Card
        color='#000000'
        sx={{
          maxWidth: '440px',
          width: 'calc(100% - 40px)',
          boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
          minHeight: '320px',
          maxHeight: '338px',
          marginBottom: '28px',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '44px',
          backgroundColor: '#fff',
          borderRadius: 0,
        }}>
        <Typography
          variant='h5'
          sx={{
            fontWeight: 600,
            fontSize: '1.5rem',
            marginTop: '16px',
            marginBottom: '12px',
          }}>
          {t('auth.redirectToConnectionPage')}
        </Typography>
      </Card>
    </Backdrop>
  );
};
