import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

export const LogoutPage = (): null => {
  const { instance, accounts } = useMsal();

  const user = accounts[0];

  useEffect(() => {
    instance.logoutRedirect({
      account: user,
    });
  }, [instance, user]);
  return null;
};
