import { Box, SxProps } from '@mui/material';
import { CSSProperties, forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { backgroundErrorColor, black } from '../../constants/colors';

type Props = {
  disabled?: boolean;
  value?: number | null | undefined;
  onChange?: (newValue: number | null) => void;
  onBlur?: (newValue: number) => void;
  maxDecimal?: number;
  maxValue?: number;
  placeholder?: string;
  error?: boolean;
  name?: string;
  style?: CSSProperties;
  sx?: SxProps;
  suffix?: string;
};

export const InlineNumberInput = forwardRef<HTMLInputElement, Props>(({ style = {}, sx = {}, ...props }: Props, ref): JSX.Element => {
  const { maxDecimal = 2, maxValue = Number.MAX_SAFE_INTEGER, onChange, onBlur, suffix, placeholder } = props;
  const value = props.value;

  const onNumberBlur = (): void => {
    onChange?.(value ?? 0);
    onBlur?.(value ?? 0);
  };

  return (
    <Box
      sx={{
        '&>input': {
          paddingLeft: '.5rem',
          border: '1px solid transparent',
          outline: 'none',
          width: '100%',
          backgroundColor: 'inherit',
          fontFamily: 'Inter',
          fontSize: '14px',
          lineHeight: '31px',
          color: 'black',
          ...(props.error &&
            !props.disabled && {
              color: (theme) => theme.palette.error.main,
              borderBottom: (theme) => `1px solid ${theme.palette.error.main}`,
              backgroundColor: backgroundErrorColor,
            }),
          ...(style || {}),
        },
        '&>input:focus': {
          border: `1px solid ${black}`,
        },
        ...sx,
      }}>
      <NumericFormat
        getInputRef={ref}
        decimalScale={maxDecimal}
        allowNegative={false}
        fixedDecimalScale
        allowedDecimalSeparators={['.']}
        decimalSeparator='.'
        thousandSeparator=''
        suffix={suffix}
        name={props.name}
        onValueChange={({ floatValue }) => {
          if (floatValue === undefined) {
            onChange?.(null);
          } else {
            onChange?.(floatValue < maxValue ? floatValue : maxValue);
          }
        }}
        onBlur={onNumberBlur}
        value={value ?? undefined}
        disabled={props.disabled}
        placeholder={placeholder}
        style={style}
        aria-invalid={props.error}
      />
    </Box>
  );
});
