import 'react-toastify/dist/ReactToastify.css';

import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

export const PageLoader = (): JSX.Element => (
  <Grid container justifyContent='center' sx={{ paddingTop: '25rem' }}>
    <CircularProgress />
  </Grid>
);
