import axios from 'axios';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../../paths';
import { useLoaderData } from 'react-router-dom';
import { ContractAgreementLoaderData } from '../../../validationNext/loaders/contractAgreementLoader';
import { GetContractAgreementRecord } from '../../models/shared/models';

export const getContractAgreementQueryKey = (id: string | undefined): QueryKey => [Path.ValidationsPage, Path.ContractAgreementsPage, id];

export const contractAgreementQueryOptions = (
  id: string | undefined,
): Pick<DefinedInitialDataOptions<GetContractAgreementRecord>, 'queryKey' | 'queryFn' | 'staleTime'> => ({
  queryKey: getContractAgreementQueryKey(id),
  queryFn: async () => axios.get<GetContractAgreementRecord>(`/contract-agreements/${id}`).then((response) => response.data),
  staleTime: 2 * 60 * 1000,
});

export const useGetContractAgreement = (contractAgreementId: string | undefined): UseQueryResult<GetContractAgreementRecord, unknown> => {
  const { contractAgreement } = useLoaderData() as ContractAgreementLoaderData;
  return useQuery({
    ...contractAgreementQueryOptions(contractAgreementId),
    initialData: contractAgreement?.data ?? undefined,
    enabled: !!contractAgreementId,
  });
};
