const languages = {
  en: 'Anglais',
  fr: 'Français',
};

const access = {
  title: 'Accès',
  rolesLabel: 'Rôles',
  accessLevels: "Niveaux d'accès",
  error: "Une erreur est survenue. La section sur les accès n'a pas pu être chargée.",
  roles: {
    admin: 'Admin',
    billingClerk: 'Agent de facturation',
    dispatcher: 'Répartiteur',
  },
};

export const userSettings = {
  about: 'À propos',
  language: 'Langue',
  languages,
  settings: 'Paramètres',
  access,
};
