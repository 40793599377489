import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import Check from '@mui/icons-material/CheckBox';
import { backgroundErrorColor, defaultDarkColor, white } from '../../../common/constants/colors';
import { forwardRef } from 'react';
import { CheckBoxOutlineBlank } from '@mui/icons-material';

type Props = {
  name: string | null | undefined;
  checked: boolean;
  onChange: (newState: boolean) => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
} & ButtonProps;

export const OperatorCheckbox = forwardRef<HTMLButtonElement, Props>(
  ({ checked, name, onChange, error, errorMessage, disabled, ...props }, ref) => {
    const backgroundColor = checked ? defaultDarkColor : 'inherit';
    const fontColor = checked ? white : defaultDarkColor;

    return (
      <Tooltip disableInteractive title={errorMessage} placement='top' arrow>
        <Box>
          {(!disabled || checked) && (
            <Button
              onClick={() => {
                onChange(!checked);
              }}
              ref={ref}
              disabled={disabled}
              aria-disabled={disabled}
              aria-checked={checked}
              aria-selected={checked}
              variant='outlined'
              startIcon={
                checked ? (
                  <Check fontSize='small' sx={{ backgroundColor: backgroundColor, color: fontColor, p: 0 }} />
                ) : (
                  <CheckBoxOutlineBlank sx={{ color: fontColor, p: 0 }} />
                )
              }
              {...props}
              sx={{
                '&:hover': { backgroundColor: !checked ? '#3131311F' : '#313131F0' },
                '&:disabled': { backgroundColor: backgroundColor, color: fontColor },
                padding: '4px 10px',
                borderRadius: 1,
                marginLeft: 0.5,
                fontSize: '0.81rem',
                backgroundColor,
                textTransform: 'uppercase',
                color: fontColor,
                ...(error &&
                  !disabled && {
                    color: (theme) => theme.palette.error.main,
                    backgroundColor: backgroundErrorColor,
                  }),
              }}>
              {name}
            </Button>
          )}
        </Box>
      </Tooltip>
    );
  },
);
