import { Params, defer } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { contractAgreementWithTimesheetEntriesSummariesQueryOptions } from '../hooks/useGetContractAgreementWithTimesheetEntriesSummaries';
import { GetCsWithEntriesSummariesRecord } from '../models/models';
import { ordreDeTravailNumberQueryOptions } from '../hooks/useGetOrdreDeTravailNumber';
import { ValidationParams } from '../params';

export type OrdreDeTravailScheduleLoaderData = {
  otNumber: { data?: string };
  operatorContractAgreements: { data?: GetCsWithEntriesSummariesRecord[] };
};

export const validationOrdreDeTravailScheduleLoader =
  (queryClient: QueryClient) =>
  ({ params }: { params: Params<ValidationParams> }): ReturnType<typeof defer> => {
    const fetchOrdreDeTravailNumber = queryClient.fetchQuery({
      ...ordreDeTravailNumberQueryOptions(params.ordreDeTravailId!),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });

    const fetchOperatorContractAgreements = queryClient.fetchQuery({
      ...contractAgreementWithTimesheetEntriesSummariesQueryOptions(params.ordreDeTravailId!),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });

    return defer({
      otNumber: fetchOrdreDeTravailNumber,
      operatorContractAgreements: fetchOperatorContractAgreements,
    });
  };
