import axios from 'axios';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../paths';

import { useLoaderData } from 'react-router-dom';
import { ContractAgreementLoaderData } from '../loaders/contractAgreementLoader';

const getOperatorDailyTimeSheetIdsQueryKey = (id: string | undefined): QueryKey => [
  Path.ValidationsPage,
  Path.ContractAgreementsPage,
  id,
  Path.TimesheetsPage,
  'ids',
];

export const operatorDailyTimeSheetIdsQueryOptions = (
  id: string | undefined,
): Pick<DefinedInitialDataOptions<string[]>, 'queryKey' | 'queryFn' | 'gcTime' | 'staleTime'> => ({
  queryKey: getOperatorDailyTimeSheetIdsQueryKey(id),
  queryFn: async () => axios.get<string[]>(`/contract-agreements/${id}/daily-time-sheet-ids`).then((response) => response.data),
  staleTime: 2 * 60 * 1000,
});

export const useGetOperatorDailyTimeSheetIds = (contractAgreementId: string | undefined): UseQueryResult<string[], unknown> => {
  const { operatorDailyTimeSheetIds } = useLoaderData() as ContractAgreementLoaderData;
  return useQuery({
    ...operatorDailyTimeSheetIdsQueryOptions(contractAgreementId),
    initialData: operatorDailyTimeSheetIds?.data,
    enabled: !!contractAgreementId,
  });
};
