import { backgroundErrorColor, black } from '../../constants/colors';
import theme from '../../../mui/theme';

export const inlineInputStyle = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderRadius: 0,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1px solid transparent`,
    borderBottomColor: theme.palette.error.main,
    backgroundColor: backgroundErrorColor,
  },
  '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: `transparent`,
    borderBottomColor: theme.palette.error.main,
    borderBottom: `1px solid ${theme.palette.error.main}`,
    backgroundColor: backgroundErrorColor,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${black}`,
  },
  '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${black}`,
  },
};
