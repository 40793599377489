import { WorkKind } from '../../generated/schemas';
import { CompilablePremiumsEnum, NonCompilablePremiumsEnum } from '../enums/PremiumsEnum';

const timesheetType = {
  linked: 'Liée',
  mixed: 'Mixte',
  nonLinked: 'Non-liée',
};

const billableTimeSheetEntry = {
  client: 'Nom du client',
  title: 'OT:',
  contractAgreement: 'Contrat de service',
  crane: 'Grue',
  comments: 'Commentaires',
  workType: 'Nature du travail',
};

const nonBillableTimeSheetEntry = {
  comments: 'Commentaires',
  title: 'Non-liée',
  workType: 'Nature du travail',
};

const timeSheetEntryStatus = {
  validated: 'Validée',
  submitted: 'Soumise',
  completed: 'Terminée',
  ongoing: 'En cours',
  submissionError: 'Erreur',
};

const timeSheetEntryValidationStatus = {
  documentsReceived: 'Document reçu',
  inProgress: 'En cours',
  open: 'Ouvert',
  submitted: 'Soumise',
};

const headers = {
  craneOperator: 'Grutier',
  date: 'Date',
  type: 'Type',
  status: 'Statut de CT',
  branchNumber: 'Succursale du grutier',
  dispatchBranch: 'Succursale de répartition',
  jobCode: 'Code métier',
  validationStatus: 'Statut de validation',
};

const list = {
  title: 'Cartes de temps',
  headers,
};

const submitDailyTimeSheetModal = {
  title: 'Soumettre la carte de temps?',
  content: 'Tous les blocs de la carte de temps seront soumis.',
  cancel: 'Annuler',
  submit: 'Soumettre',
};

const saveDailyTimeSheetBlockModal = {
  title: 'Avertissement',
  content: "Une fois la carte de temps terminée, l'entrée de temps ne sera plus modifiable sur l’application mobile.",
  cancel: 'Annuler',
  save: 'Enregistrer',
};

const manualTimeSheetNumberModal = {
  title: 'Lier une carte de temps papier',
  content: `Inscrire le numéro d'une carte de temps papier pour la lier à l'entrée de carte de temps WeLift. Ce nouveau numéro remplacera l'ancien.`,
  label: 'Numéro',
  cancel: 'Annuler',
  link: 'Lier',
};

const detail = {
  title: 'Carte de temps',
  export: 'Exporter',
  saveSuccessMessage: 'Les modifications à la carte de temps ont été enregistrées avec succès',
  addTimeRange: 'Ajouter',
  submit: 'Soumettre',
  reviewedBy: 'Révisé par',
  submitDailyTimeSheetModal,
  saveDailyTimeSheetBlockModal,
  manualTimeSheetNumberModal,
};

const timesheetTimes = {
  title: 'Temps',
  edit: 'Modifier',
  from: 'De',
  to: 'À',
  poste: 'Poste',
  type: 'Nature',
  quantity: 'Qté',
  workOrderNumber: 'Equi./WO',
  gtNumber: '#GT',
  simple: 'Simple',
  singleAndAHalf: 'Demi',
  double: 'Double',
  automaticPrime: 'P. Aut',
  variablePrime: 'P. Var',
  automaticPrimeLong: 'Prime Auto',
  variablePrimeLong: 'Prime Vari',
  rate: 'Taux',
  total: 'Total',
};

const premium = {
  title: 'Indemnités',
  manTime: 'Temps homme',
  noBreak: 'No Break',
  noLunch: 'No Lunch',
  manTransport: 'Transport homme',
  meal: 'Repas',
  preparation: 'Préparation',
  taxis: 'Taxis',
  parking: 'Stationnement',
  travellingExpenses: 'Frais de déplacement',
  pension: 'Pension',
  workKind: 'Genre',
  branch: 'Succursale',
  contractAgreement: 'No CS',
  poste: 'Poste',
  roomAndBoardDay: 'Jour',
  roomAndBoardTwoPlusWeeks: '2+ semaines',
};

const premiums = {
  [CompilablePremiumsEnum.ManTimePremium]: 'Temps homme',
  [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
  [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
  [CompilablePremiumsEnum.ManTransportPremium]: 'Transport homme',
  [CompilablePremiumsEnum.PreparationPremium]: 'Préparation',
  [NonCompilablePremiumsEnum.MealPremium]: 'Repas',
  [NonCompilablePremiumsEnum.TaxisPremium]: 'Taxis',
  [NonCompilablePremiumsEnum.ParkingPremium]: 'Stationnement',
  [NonCompilablePremiumsEnum.TravellingExpensesPremium]: 'Frais de déplacement',
  [NonCompilablePremiumsEnum.PensionPremium]: 'Pension',
  [NonCompilablePremiumsEnum.CarAllowancePremium]: 'Utilisation du Véhicule',
};

const distanceRange = {
  [WorkKind.TravelExpenses49To72Km]: '49 à 72 km',
  [WorkKind.TravelExpenses73To88Km]: '73 à 88 km',
  [WorkKind.TravelExpenses89To119Km]: '89 à 119 km',
};

const inlineTimesheet = {
  title: 'Temps',
  addTime: 'Ajouter une plage',
  header: {
    numOT: 'No OT',
    from: 'De',
    to: 'À',
    branch: 'Suc.',
    qty: 'Qté',
    poste: 'Poste',
    unitNo: 'No unité',
    numCA: 'No CS',
    single: 'Sim.',
    singleAndHalf: 'Demi',
    double: 'Dou.',
    variablePrime: 'Prime Vari',
    automaticPrime: 'Prime Auto',
    rate: 'Taux',
  },
  footer: {
    total: 'Total',
  },
};

const summary = {
  time: 'Temps',
  comments: 'Commentaires',
  premiums: 'Indemnités',
  premiumValue: 'Quantité | Heures',
  contractAgreement: 'Contrat de service',
};

const timeRangeSection = {
  onError: `Veuillez corriger les erreurs dans les cartes de temps avant d'enregistrer.`,
  cantEdit: 'Un bloc lié/non-lié est déjà en cours de modification.',
  manuallyLinked: 'Numéro manuel',
  linkPaperTimeSheet: 'Lier CT papier',
};

export const timesheet = {
  days_one: '{{count}} jour',
  days_other: '{{count}} jours',
  timesheets: 'Cartes de temps',
  submitErrorMsg: "Une erreur technique est survenue lors de la soumission à l'AS400",
  submitSuccessMsg: "La validation a été soumise avec succès à l'AS400",
  manuallyCreated: 'Créé manuellement',
  timeSheetEntryStatus,
  timesheetType,
  list,
  billableTimeSheetEntry,
  nonBillableTimeSheetEntry,
  detail,
  timesheetTimes,
  premium,
  distanceRange,
  premiums,
  inlineTimesheet,
  summary,
  timeRangeSection,
  timeSheetEntryValidationStatus,
};
