import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';

export const ContractAgreementDetailRouterId = 'ContractAgreementPage';
export const ContractAgreementOutletRouterId = 'ContractAgreementOutlet';

export const useIsContractAgreementConsultation = (): boolean => {
  const routeMatches = useMatches();
  return useMemo(() => routeMatches.some((m) => m.id === ContractAgreementDetailRouterId), [routeMatches]);
};
