import { SvgIcon, SvgIconProps, Tooltip } from '@mui/material';
import { MouseEventHandler, useCallback } from 'react';
import { black } from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import { formatDateLong } from '../../helpers/DateTimeHelper';
import { addDays } from 'date-fns';

const activeOpacity = 1;
const disabledOpacity = 0.3;
const inactiveOpacity = 0.6;
const hoveringInactiveOpacity = 0.8;

type Props = {
  active?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<SVGSVGElement>;
  dateToIncrement?: Date | undefined;
} & SvgIconProps;

const getOpacity = (active: boolean, disabled: boolean) => {
  if (disabled) return disabledOpacity;
  return active ? activeOpacity : inactiveOpacity;
};

const getStyle = (active: boolean, hasEvent: boolean, readonly: boolean, disabled: boolean) => {
  const hasOnClickBehaviour = hasEvent && !readonly && !disabled;
  return {
    height: '24px',
    width: '24px',
    opacity: getOpacity(active, disabled),
    '&:hover': {
      cursor: hasOnClickBehaviour ? 'pointer' : 'default',
      opacity: hasOnClickBehaviour ? hoveringInactiveOpacity : undefined,
    },
  };
};

export const NextDayIcon = ({
  active = false,
  disabled = false,
  readonly = false,
  onClick,
  dateToIncrement = new Date(),
  ...props
}: Props) => {
  const { i18n } = useTranslation();
  const formattedDate: string = formatDateLong(addDays(dateToIncrement, 1), i18n.resolvedLanguage) ?? '???';
  const handleClick: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      if (!readonly && !disabled) {
        onClick?.(event);
      } else {
        event.preventDefault();
      }
    },
    [onClick, readonly, disabled],
  );

  return (
    <Tooltip title={active ? formattedDate : undefined}>
      <SvgIcon
        {...props}
        onClick={handleClick}
        aria-disabled={disabled}
        aria-selected={active}
        viewBox='0 0 24 24'
        sx={getStyle(active, !!onClick, readonly, disabled)}
        data-testid='tomorrow-icon'>
        <path
          d='M15.4609 16.7888C15.4609 17.0384 15.2497 17.2688 15 17.2688H4.63203C4.38239 17.2688 4.15203 17.0576 4.15203 16.7888V9.9152H15.4608V11.816H16.6129L16.6128 7.97603C16.6128 7.07363 15.8831 6.34397 14.9807 6.34397H13.464V5.57603C13.464 5.24965 13.1952 5 12.8879 5H12.1776C11.8512 5 11.5824 5.26879 11.5824 5.57603V6.34397L8.0304 6.3441V5.57617C8.0304 5.24979 7.76161 5.00013 7.45437 5.00013H6.744C6.41762 5.00013 6.16797 5.26893 6.16797 5.57617V6.3441H4.63207C3.72967 6.3441 3 7.07373 3 7.97617V16.789C3 17.6914 3.72963 18.421 4.63207 18.421H15.0001C15.9025 18.421 16.6321 17.6914 16.6321 16.789V15.3298H15.4801V16.7888L15.4609 16.7888Z'
          fill={black}
          fillOpacity='0.54'
        />
        <path
          d='M21.0673 13.2369L18.744 11.4514C18.4561 11.2402 18.0528 11.4322 18.0528 11.7778V12.6417H13.7712C13.6176 12.6417 13.4833 12.7762 13.4833 12.9297V14.2161C13.4833 14.3697 13.6177 14.504 13.7712 14.504L18.072 14.5042V15.3681C18.072 15.7137 18.4753 15.9249 18.7632 15.6945L21.0865 13.909C21.2976 13.7361 21.2976 13.4097 21.0672 13.2369L21.0673 13.2369Z'
          fill={black}
          fillOpacity='0.54'
        />
        {active && (
          <path
            d='M15 10.6301V11.3289C15 11.605 14.7762 11.8289 14.5 11.8289H13.772C13.2197 11.8289 12.772 12.2766 12.772 12.8289V14.3008C12.772 14.8531 13.2197 15.3008 13.772 15.3008H14.5C14.7762 15.3008 15 15.5246 15 15.8008V15.9999C15 16.2761 14.7762 16.4999 14.5 16.4999H5.34872C5.07258 16.4999 4.84872 16.2761 4.84872 15.9999V10.6301H15Z'
            fill={black}
            fillOpacity='0.54'
          />
        )}
      </SvgIcon>
    </Tooltip>
  );
};
