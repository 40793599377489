import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

export const dispatchAbility = 'dispatch';

type DispatchAbilityContext = {
  branchIds: string[];
};

export const defineDispatchAbilities = (
  abilityBuilder: AbilityBuilder<AppAbility>,
  roles: Role[] | undefined,
  userBranchIds: string[] | undefined,
): void => {
  const { can } = abilityBuilder;

  if (roles?.some((role: Role) => role === 'admin')) {
    can('edit', dispatchAbility);
  } else if (roles?.some((role: Role) => role === 'dispatcher' || role === 'billingClerk')) {
    if (!!userBranchIds) {
      can('edit', dispatchAbility, ({ branchIds }: DispatchAbilityContext) => {
        if (!branchIds.length) return false;
        return branchIds.every((branchId) => userBranchIds?.some((userBranchId) => userBranchId === branchId));
      });
    }
  }
};
