import axios, { AxiosError } from 'axios';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../paths';
import { PrefacturationRecord } from '../models/models';
import { useLoaderData } from 'react-router-dom';
import { PrefacturationLoaderData } from '../loaders/PrefacturationLoader';

export const getPrefacturationQueryKey = (number: string | undefined): QueryKey => [
  Path.ValidationsPage,
  Path.OrdresDeTravailPage,
  Path.NextPrefacturationSuffix,
  'schedule',
  number,
];

export const prefacturationQueryOptions = (
  otNumber: string | undefined,
): Pick<DefinedInitialDataOptions<PrefacturationRecord, AxiosError>, 'queryKey' | 'queryFn' | 'gcTime' | 'staleTime'> => {
  const cleanedOtNumber = otNumber?.replace('-', '');
  return {
    queryKey: getPrefacturationQueryKey(cleanedOtNumber),
    queryFn: async () =>
      axios.get<PrefacturationRecord>(`/ordre-de-travail/continuity/${cleanedOtNumber}/prefacturation`).then((response) => response.data),
    staleTime: 1000 * 60 * 2,
  };
};

export const useGetPrefacturation = (otNumber: string | undefined): UseQueryResult<PrefacturationRecord, AxiosError> => {
  const { prefacturations } = useLoaderData() as PrefacturationLoaderData;
  return useQuery({
    ...prefacturationQueryOptions(otNumber),
    initialData: prefacturations?.data,
    enabled: !!otNumber,
  });
};
