export const DISPLAY_APP_ERROR_MESSAGE = Symbol('display-app-error-message');
export type PubSubDisplayAppErrorMessageEvent = { errorMessage: string | undefined };

export const DISPLAY_VALIDATION_ERROR = Symbol('display-app-error-message');
export interface PubSubDisplayValidationErrorEvent {
  variant: 'contractAgreement' | 'timesheet';
  validationError?: Record<string, string[]>;
}
export const CONTRACT_AGREEMENT_VALIDATION_CONTEXT = Symbol('updated-contract-agreement-state');
export interface ContractAgreementValidationContext {
  totalCraneMinutes: number;
}
export const HIDE_VALIDATION_ERROR = Symbol('hide-validation-error');

export const ONLY_NOT_SUBMITTED_TIME_SHEETS = Symbol('only-not-submitted-time-sheets');

export const CONTRACT_AGREEMENT_HEADER_LOADING = Symbol('contract-agreement-header-loading');
