import { ValidationParams } from '../params';
import { ordreDeTravailNumberQueryOptions } from '../hooks/useGetOrdreDeTravailNumber';
import { QueryClient } from '@tanstack/react-query';
import { defer, Params } from 'react-router-dom';
import { prefacturationQueryOptions } from '../hooks/useGetPrefacturation';
import { AccessoryTypeRecord, GetCodesDeFacturationRecord, PrefacturationRecord, WorkForceTypeRecord } from '../models/models';
import { accessoryTypeOptions, codesDeFacturationOptions, workForceTypesOptions } from '../hooks/useGetPrefacturationInformations';
import { OrdreDeTravailInfoRecord } from '../../common/models/models';
import { ordreDeTravailInfoQueryOptions } from '../../common/hooks/useGetOrdreDeTravailInfo';

export type PrefacturationLoaderData = {
  otNumber: { data?: string };
  prefacturations: { data?: PrefacturationRecord };
  otInfo: { data?: OrdreDeTravailInfoRecord };
  codesDeFacturation: { data?: GetCodesDeFacturationRecord[] };
  workForceTypes: { data?: WorkForceTypeRecord[] };
  accessoryTypes: { data?: AccessoryTypeRecord[] };
};
export const PrefacturationLoader = (queryClient: QueryClient) => {
  return ({ params }: { params: Params<ValidationParams> }): ReturnType<typeof defer> => {
    const fetchOrdreDeTravailNumber = queryClient.fetchQuery({
      ...ordreDeTravailNumberQueryOptions(params.ordreDeTravailId!),
      staleTime: 1000 * 60 * 2, // 2 minutes
    });
    const fetchPrefacturation = fetchOrdreDeTravailNumber.then((otNumber) =>
      queryClient.fetchQuery({ ...prefacturationQueryOptions(otNumber), staleTime: 1000 * 60 * 2 }),
    );
    const fetchOTInfo = queryClient.fetchQuery({
      ...ordreDeTravailInfoQueryOptions(params.ordreDeTravailId!),
      staleTime: 1000 * 60 * 2,
    });
    const fetchCodesDeFacturation = queryClient.fetchQuery({
      ...codesDeFacturationOptions(),
      staleTime: 1000 * 60 * 2,
    });
    const fetchWorkForceTypes = queryClient.fetchQuery({
      ...workForceTypesOptions(),
      staleTime: 1000 * 60 * 2,
    });
    const fetchAccessoryTypes = queryClient.fetchQuery({
      ...accessoryTypeOptions(),
      staleTime: 1000 * 60 * 2,
    });

    return defer({
      otNumber: fetchOrdreDeTravailNumber,
      prefacturations: fetchPrefacturation,
      otInfo: fetchOTInfo,
      codesDeFacturation: fetchCodesDeFacturation,
      workForceTypes: fetchWorkForceTypes,
      accessoryTypes: fetchAccessoryTypes,
    });
  };
};
