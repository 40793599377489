import { NotificationStatus, WorkType } from '../../generated/schemas';
import { NotificationStatusFilter } from '../../common/enums/NotificationStatusFilterEnum';

const notificationStatuses = {
  [NotificationStatus.Confirmed]: 'Confirmé',
  [NotificationStatus.Read]: 'Lu, non-confirmé',
  [NotificationStatus.Received]: 'Reçu',
  [NotificationStatus.Sent]: 'Envoyé',
  [NotificationStatusFilter.Confirmed]: 'Confirmé',
  [NotificationStatusFilter.Read]: 'Lu, non-confirmé',
  [NotificationStatusFilter.Received]: 'Reçu',
  [NotificationStatusFilter.Sent]: 'Envoyé',
};

const workTypes = {
  [WorkType.Industrial]: 'Industriel (Baie James isolé)',
  [WorkType.RegularLabor]: 'Travail régulier',
  [WorkType.IndustrialSite]: 'Chantier industriel',
  [WorkType.HeavyIndustries]: 'Industries lourdes',
  [WorkType.Pipeline]: 'Pipeline',
  [WorkType.PowerLineErection]: 'Ligne électrique - Érection',
  [WorkType.GeneralElectricPostLine]: 'Ligne poste électrique - Général',
  [WorkType.CivilEngineeringAndRoads]: 'Génie civil et voirie',
  [WorkType.WindTurbine]: 'Éoliennes',
  [WorkType.Barracks]: 'Baraquement',
  [WorkType.JamesBayAndIsolatedWorksite]: 'Baie-James & Chantiers isolés',
  [WorkType.OutsideProvinceQuebec]: 'Extérieur province Québec',
};

const notificationType = {
  newOrdreDeTravailNotification: 'Assignation',
  ordreDeTravailContinuityNotification: 'Continuité',
  ordreDeTravailUpdatedNotification: 'Modification',
  ordreDeTravailCancelledNotification: 'Annulation',
  unassignedOrdreDeTravailNotification: 'Retrait',
};

const headers = {
  ordreDeTravailNumber: "Numéro de l'OT",
  lastNotificationType: 'Notification',
  operatorBranchNumber: 'Succursale du grutier',
  operator: 'Grutier',
  unitNo: 'No Unité',
  clientName: 'Client',
  ordreDeTravailStatus: "Statut de l'OT",
  notificationStatus: 'Statut de Lecture',
  date: 'Date',
  workType: 'Nature du Travail',
  workDescription: 'Description du Travail',
  representativeName: 'Chargé de projet',
  site: 'Chantier',
  dispatchBranchNumber: 'Succursale de répartition',
  salesBranchNumber: 'Succursale des ventes',
};

const list = {
  title: 'Suivis de lecture',
  notificationStatuses,
  headers,
  workTypes,
  notificationType,
};

export const acknowledgement = {
  list,
};
