import { PropsWithChildren } from 'react';
import { useEmployeeContext } from '../common/contexts/EmployeeContext';
import defineAbilitiesFor from './Ability';
import { AbilityContext } from './Can';

export const AbilityProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const { employee, error } = useEmployeeContext();

  return <AbilityContext.Provider value={defineAbilitiesFor(employee, error)}>{children}</AbilityContext.Provider>;
};
