import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from './Ability';
import { AxiosError } from 'axios';
import { Role } from '../common/models/models';

const readRoles: Role[] = ['dispatcher', 'billingClerk', 'admin'];

export const appAbility = 'app';

export const defineAppAbilities = (
  abilityBuilder: AbilityBuilder<AppAbility>,
  roles: Role[] | undefined,
  error: AxiosError | null | undefined,
): void => {
  const { can } = abilityBuilder;

  const isAuthorized = !error || (error.response?.status !== 401 && error.response?.status !== 403);
  const employeeHasReadRole = !error && !!roles?.some((role) => readRoles.includes(role));

  if (isAuthorized && employeeHasReadRole) {
    can('access', appAbility);
  }
};
