import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { OrdreDeTravailInfoRecord, Role } from '../../common/models/models';

export const defineContractAgreementAbilities = (
  abilityBuilder: AbilityBuilder<AppAbility>,
  roles: Role[] | undefined,
  userBranchIds: string[] | undefined,
): void => {
  const { can } = abilityBuilder;

  if (roles?.some((role: string) => role === 'admin')) {
    can('view', 'contractAgreement');
    can('edit', 'contractAgreement');
  } else if (roles?.some((role: string) => role === 'dispatcher' || role === 'billingClerk')) {
    can('view', 'contractAgreement');
    if (!!userBranchIds) {
      can('edit', 'contractAgreement', (ordreDeTravail: OrdreDeTravailInfoRecord) => {
        const salesBranchId = ordreDeTravail?.salesBranchId;
        return salesBranchId ? userBranchIds.includes(salesBranchId || '') : true;
      });
      can('edit', 'contractAgreement', (ordreDeTravail: OrdreDeTravailInfoRecord) => {
        const dispatchBranchId = ordreDeTravail?.dispatchBranchId;
        return dispatchBranchId ? userBranchIds.includes(dispatchBranchId || '') : true;
      });
    }
  }
};
