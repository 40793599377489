export enum CompilablePremiumsEnum {
  ManTimePremium = 'ManTimePremium',
  NoBreakTimesPremium = 'NoBreakTimesPremium',
  NoLunchTimesPremium = 'NoLunchTimesPremium',
  ManTransportPremium = 'ManTransportPremium',
  PreparationPremium = 'PreparationPremium',
}

// Order of this is enum is important. It is used to determine the order of the premium fields in the timesheet form.
export enum NonCompilablePremiumsEnum {
  MealPremium = 'MealPremium',
  TaxisPremium = 'TaxisPremium',
  ParkingPremium = 'ParkingPremium',
  TravellingExpensesPremium = 'TravellingExpensesPremium',
  CarAllowancePremium = 'CarAllowancePremium',
  PensionPremium = 'PensionPremium',
}

export function getPremiumType(type?: string | null): CompilablePremiumsEnum | NonCompilablePremiumsEnum | null {
  switch (type) {
    case CompilablePremiumsEnum.ManTimePremium:
      return CompilablePremiumsEnum.ManTimePremium;
    case CompilablePremiumsEnum.ManTransportPremium:
      return CompilablePremiumsEnum.ManTransportPremium;
    case NonCompilablePremiumsEnum.MealPremium:
      return NonCompilablePremiumsEnum.MealPremium;
    case CompilablePremiumsEnum.NoBreakTimesPremium:
      return CompilablePremiumsEnum.NoBreakTimesPremium;
    case CompilablePremiumsEnum.NoLunchTimesPremium:
      return CompilablePremiumsEnum.NoLunchTimesPremium;
    case NonCompilablePremiumsEnum.ParkingPremium:
      return NonCompilablePremiumsEnum.ParkingPremium;
    case NonCompilablePremiumsEnum.PensionPremium:
      return NonCompilablePremiumsEnum.PensionPremium;
    case CompilablePremiumsEnum.PreparationPremium:
      return CompilablePremiumsEnum.PreparationPremium;
    case NonCompilablePremiumsEnum.TaxisPremium:
      return NonCompilablePremiumsEnum.TaxisPremium;
    case NonCompilablePremiumsEnum.TravellingExpensesPremium:
      return NonCompilablePremiumsEnum.TravellingExpensesPremium;
    case NonCompilablePremiumsEnum.CarAllowancePremium:
      return NonCompilablePremiumsEnum.CarAllowancePremium;
    default:
      return null;
  }
}
