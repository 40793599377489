import { forwardRef, MouseEventHandler, ReactNode, useCallback, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Stack, TableCell, TableRow, Theme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ActionButton } from '../../../common/components/button/ActionButton';
import { Add } from '@mui/icons-material';

export type SectionProps = LinkedSectionProps | UnlinkedSectionProps;

type Props = {
  label: ReactNode;
  statusPill?: ReactNode;
  sectionKey: string;
  onAdd?: () => void;
  readonly?: boolean;
  children?: ReactNode;
  colSpan?: number;
};

type LinkedSectionProps = {
  isLinked: true;
  number: string | undefined;
  path: string;
} & Props;

type UnlinkedSectionProps = {
  isLinked?: false;
} & Props;

export const SimpleSectionHeader = ({ label, colSpan }: SimpleSectionHeaderProps) => {
  return (
    <TableRow key={label} data-variant='header'>
      <TableCell colSpan={colSpan}>
        <Typography sx={{ fontSize: '0.875rem', textTransform: 'uppercase' }}>{label}</Typography>
      </TableCell>
    </TableRow>
  );
};

export type SectionHeaderRef = {
  // no actions yet;
};

export type SimpleSectionHeaderProps = {
  label: string;
  colSpan: number;
};

export const SectionHeader = forwardRef<SectionHeaderRef, SectionProps>(
  ({ sectionKey: sectionKey, statusPill, label, onAdd, readonly = false, children, isLinked = false, colSpan = 12, ...rest }, ref) => {
    const { t } = useTranslation('contractAgreement');

    useImperativeHandle(ref, () => ({
      // no actions yet;
    }));

    const handleAdd = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        event.stopPropagation();
        onAdd?.();
      },
      [onAdd],
    );

    return (
      <>
        <TableRow key={sectionKey} data-variant='header'>
          <TableCell colSpan={colSpan}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2' sx={{ textTransform: 'uppercase', display: 'flex', alignItems: 'center' }}>
                  {label}
                </Typography>
                {isLinked === true && (
                  <>
                    {(rest as LinkedSectionProps).number ? (
                      <Typography
                        variant='body2'
                        component={Link}
                        to={(rest as LinkedSectionProps).path}
                        style={{ padding: 0, marginLeft: 0 }}
                        sx={(theme: Theme) => ({
                          ml: 0,
                          ':not(:hover)': {
                            textDecoration: 'none',
                          },
                          textTransform: 'uppercase',
                          color: theme.palette.primary.light,
                        })}>
                        {(rest as LinkedSectionProps).number}
                      </Typography>
                    ) : (
                      <Skeleton width={70} />
                    )}
                  </>
                )}
                {statusPill}
              </Stack>
              <Box alignItems='center' sx={{ opacity: readonly ? 0 : 1, transition: 'ease 0.3s opacity' }}>
                {typeof onAdd === 'function' && (
                  <ActionButton
                    data-testid={`addRow-${sectionKey}`}
                    aria-label='add'
                    disabled={readonly}
                    label={t('common:button.add')}
                    startIcon={<Add />}
                    onClick={handleAdd}
                    size='small'
                  />
                )}
              </Box>
            </Stack>
          </TableCell>
        </TableRow>
        {children}
      </>
    );
  },
);
