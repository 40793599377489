import { AbilityBuilder, AbilityClass, AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';
import { defineConfigurationAbilities } from '../configuration/authorization/abilities';
import { defineContractAgreementAbilities } from '../contractAgreement/authorization/abilities';
import { defineDispatchAbilities } from '../dispatch/authorization/abilities';
import { defineAppAbilities } from './abilities';
import { AxiosError } from 'axios';
import { EmployeeAccessRecord } from '../common/models/models';
import { definedReturnForCorrectionAbilities } from '../validationNext/authorization/abilities';

export type AppAbility = PureAbility<AbilityTuple, MatchConditions>;
const AppAbility = PureAbility as AbilityClass<AppAbility>;

export default function defineAbilitiesFor(user: EmployeeAccessRecord | undefined, error: AxiosError | null | undefined): AppAbility {
  const abilityBuilder = new AbilityBuilder(AppAbility);
  const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;
  if (!user) return abilityBuilder.build();
  const userBranchIds = user.editableBranches.map((branch) => branch.id);
  const userRoles = user.roles;

  defineAppAbilities(abilityBuilder, userRoles, error);
  defineConfigurationAbilities(abilityBuilder, userRoles);
  defineContractAgreementAbilities(abilityBuilder, userRoles, userBranchIds);
  defineDispatchAbilities(abilityBuilder, userRoles, userBranchIds);
  definedReturnForCorrectionAbilities(abilityBuilder, userRoles);

  return abilityBuilder.build({ conditionsMatcher: lambdaMatcher });
}
