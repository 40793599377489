import { BoxProps, TableCellProps, TableContainerProps, TableProps, TableRowProps } from '@mui/material';
import { tableBorderColor, tableHeaderColor } from '../../../common/constants/colors';

const tableSx: TableProps['sx'] = { borderStyle: 'hidden' };
const tableBodySx: TableCellProps['sx'] = {
  '& .MuiTableCell-root': {
    border: `1px solid ${tableBorderColor}`,
    whiteSpace: 'nowrap',
  },
};
const tableRowSx: TableRowProps['sx'] = { '& .MuiTableCell-root': { height: '2.5rem', py: 0, px: 0 } };

export const tableContainerSx: TableContainerProps['sx'] = {
  borderRadius: 1,
  border: `1px solid ${tableBorderColor}`,
  '& .MuiTable-root': tableSx,
  '& .MuiTableHead-root': tableBodySx,
  '& .MuiTableBody-root': tableBodySx,
  '& .MuiTableRow-root': tableRowSx,
  '.MuiOutlinedInput-notchedOutline': {
    border: '0',
  },
  // for the header section with the add button
  '& .MuiTableRow-root[data-variant="header"] .MuiTableCell-root': {
    backgroundColor: tableHeaderColor,
    px: 1,
  },
  // for the header cells and other typography cells like Fees title
  '& .MuiTableRow-root .MuiTypography-root': { px: 1 },
};

export const tableCardSplitSx: BoxProps['sx'] = {
  m: 1,
  p: 0.2,
  borderRadius: 1,
  border: 1,
  borderColor: (theme) => theme.palette.divider,
};

export const inputSx = { fontSize: '0.875rem', px: 1, fontFamily: 'Inter, sans-serif' };
export const selectSx = {
  height: '2.2rem',
  borderRadius: 0,
  width: 1,
  '.MuiSelect-select': { padding: '0.5rem 1rem 0.5rem 1rem' },
};
