import React, { createContext, useContext } from 'react';
import { useGetEmployeeClaimsAndRoles } from '../hooks/useGetEmployeeClaimsAndRoles';
import { EmployeeAccessRecord } from '../models/models';
import { AxiosError } from 'axios';

type employeeState = {
  employee: EmployeeAccessRecord | undefined;
  error: AxiosError | null | undefined;
  isFetching: boolean;
};

export const EmployeeContext = createContext<employeeState>({
  employee: undefined,
  isFetching: false,
  error: undefined,
});

export const useEmployeeContext = (): employeeState => useContext(EmployeeContext);

export interface EmployeeContextProviderProps {
  children: React.ReactNode;
}

const EmployeeContextProvider = ({ children }: EmployeeContextProviderProps): JSX.Element => {
  const { data: employee, error, isLoading: isFetching } = useGetEmployeeClaimsAndRoles();

  return (
    <EmployeeContext.Provider
      value={{
        employee,
        isFetching,
        error,
      }}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContextProvider;
