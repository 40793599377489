import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { AccountInfo, AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { useCallback, useEffect, useState } from 'react';

import { TFunction } from 'i18next';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { loginRequest } from '../../authConfig';
import { config } from '../../config';
import i18n from '../../i18n';
import { DISPLAY_APP_ERROR_MESSAGE } from '../types/pubsubEvents';
import { useAccessToken } from './useAccessToken';

const httpLink = new HttpLink({
  uri: `${config.API_URL}/graphql`,
});

const getErrorLink = (msalInstance: IPublicClientApplication, account: AccountInfo, t: TFunction) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (extensions?.code === 'UNAUTHENTICATED' || extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
          msalInstance.loginRedirect({ ...loginRequest, account }).catch(console.error);
        }
        if (extensions?.code === 'ConcurrencyException') {
          PubSub.publish(DISPLAY_APP_ERROR_MESSAGE, { errorMessage: t('messages.concurrencyErrorMessage') });
        }
      });
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

const useSetupApollo = (
  account: AccountInfo | null,
  instance: IPublicClientApplication,
): [ApolloClient<NormalizedCacheObject>, boolean] => {
  const [apolloClient, setApolloClient] = useState(
    new ApolloClient({
      link: undefined,
      cache: new InMemoryCache({ addTypename: false }),
    }),
  );
  const [isApolloReady, setIsApolloReady] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const getAccessToken = useAccessToken();
  const { t } = useTranslation('common');

  const setApollo = useCallback(
    async (getToken: () => Promise<void | AuthenticationResult | undefined>, activeAccount: AccountInfo) => {
      try {
        const authLink = setContext(async (_, { headers }) => {
          const token = await getToken();
          const bearerToken = token ? `Bearer ${token.accessToken}` : '';
          return {
            headers: {
              ...headers,
              Authorization: bearerToken,
              'Accept-Language': i18n.languages[0],
            },
          };
        });

        const wsLink = new GraphQLWsLink(
          createClient({
            url: config.WS_URL,
            connectionParams: async () => {
              const token = await getToken();
              const bearerToken = token ? `Bearer ${token.accessToken}` : '';
              return {
                headers: {
                  Authorization: bearerToken,
                  'Accept-Language': i18n.languages[0],
                },
              };
            },
          }),
        );

        const link = split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
          },
          wsLink,
          from([authLink, getErrorLink(instance, activeAccount, t), httpLink]),
        );
        setApolloClient(
          new ApolloClient({
            cache: new InMemoryCache({ addTypename: false }),
            link,
            defaultOptions: {
              watchQuery: {
                fetchPolicy: 'cache-and-network',
              },
            },
            connectToDevTools: config.USE_APOLLO_DEV_TOOLS,
          }),
        );
      } catch (error: unknown) {
        return Promise.reject(error);
      }
      setIsApolloReady(true);
    },
    [instance, t],
  );

  useEffect(() => {
    // FIXME: deadlock can occur here since getAccesToken is changing
    // prevent it by fixing the useContext or using a ref object on the promise to prevent cycling effect
    if (isAuthenticated && account) {
      setApollo(getAccessToken, account);
    }
  }, [isAuthenticated, setApollo, instance, account, getAccessToken]);

  return [apolloClient, isApolloReady];
};

export default useSetupApollo;
